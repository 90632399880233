@import "checkbox";

@media print {
    a[href]:after {
        content: none !important;
    }
}

#svelte-visitors {
    tr > * {
        padding: 5px 5px;
        border: 1px solid rgb(221, 221, 221);
    }

    table {
        width: 100%;
    }

    .nowrap {
        white-space: nowrap;
    }
    .date {
        text-align: left;
    }
    thead {
        background: rgba(217, 237, 247, 1);
        tr {
            th {
                padding-right: 20px;
                &.sorted {
                    position: relative;
                    &::after {
                        position: absolute;
                        top: 0px;
                        right: 5px;
                        transform: rotate(90deg);
                        font-family: "Material Icons";
                        content: "double_arrow";
                        font-size: 20px;
                        -webkit-font-feature-settings: "liga" 1;
                        -moz-font-feature-settings: "liga" 1;
                        font-feature-settings: "liga" 1;
                    }
                }
                &.down {
                    &::after {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
    td {
        vertical-align: top;
        .smaller-date {
            font-size: 12px;
        }
        .smaller-email {
            font-size: 10px;
        }
    }
    .comment {
        td {
            padding: 0;
        }
    }
    .current {
        background-color: rgba(223, 240, 216, 1);

        &:hover {
            background-color: rgba(223, 240, 216, 0.8);
        }
    }
}
