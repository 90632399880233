#svelte-visitors {
    $black: #363839;
    $lightgray: #9c9e9f;
    $gray: #bdc1c6;
    $white: #fff;
    $green: #06842c;

    input[type="checkbox"] {
        font-size: 16px;
        position: relative;
        width: 16px;
        height: 16px;
        color: $black;
        border: 1px solid $gray;
        border-radius: 4px;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
        &::before {
            position: absolute;
            content: "";
            display: block;
            top: 0px;
            left: 4px;
            width: 5px;
            height: 10px;
            border-style: solid;
            border-color: $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: 0;
        }
        &:checked {
            color: $white;
            border-color: $green;
            background: $green;
            &::before {
                opacity: 1;
            }
            
        }
    }

    label {
        position: relative;
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        padding: 0 5px 0;
        user-select: none;
        &::before {
            position: absolute;
            left:27px;
            content: attr(data-content);
            color: $lightgray;
            clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
            text-decoration: line-through;
            text-decoration-thickness: 3px;
            text-decoration-color: $black;
            transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        }
        &.checked::before {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }

    }
}
